import { Rows } from "@apis/index-common";
import httpRequest from "@networks/index";
import { GetAdminMediaBigImagesResponse } from "./response";

const ApiUrls = {
  getAdminMediaBigImages: "/admin/media/big-images",
  postAdminMediaBigOwlFiles: "/admin/media/big-owl-files",
  rdfXmlDataList: "/admin/bulk-sync-jobs",
  rdfXmlDataDetail: "/admin/bulk-sync-jobs/{id}",
};

/** 대용량 이미지 조회 */
export const requestGetAdminMediaBigImages = async ({
  apiKey,
  originName,
  page,
  pageSize,
}: {
  apiKey?: string;
  originName?: string;
  page: number;
  pageSize: number;
}): Promise<Rows<GetAdminMediaBigImagesResponse>> => {
  const params = {
    originName,
    page,
    pageSize,
  };
  const { data } = await httpRequest<Rows<GetAdminMediaBigImagesResponse>>(
    "GET",
    ApiUrls.getAdminMediaBigImages,
    params,
    undefined,
    undefined,
    apiKey
  );
  return data;
};

export const requestPostAdminMediaBigImage = async ({
  formData,
  apiKey,
}: {
  formData: any;
  apiKey: string;
}) => {
  const { data } = await httpRequest(
    "POST",
    ApiUrls.getAdminMediaBigImages,
    undefined,
    formData,
    undefined,
    apiKey,
    "form"
  );
  return data;
};

export const requestGetRdfXmlDataList = async ({
  apiKey,
  page,
  pageSize,
}: {
  apiKey?: string;
  page: number;
  pageSize: number;
}) => {
  const param = {
    page,
    pageSize,
  };
  const { data } = await httpRequest(
    "GET",
    ApiUrls.rdfXmlDataList,
    param,
    undefined,
    undefined,
    apiKey
  );
  return data;
};

export const requestGetRdfXmlDataDetail = async ({
  id,
  apiKey,
}: {
  id: number;
  apiKey: string;
}) => {
  const { data } = await httpRequest(
    "GET",
    ApiUrls.rdfXmlDataDetail.replace("{id}", String(id)),
    undefined,
    undefined,
    undefined,
    apiKey
  );

  return data;
};

export const requestPostRdfXmlOwlData = async ({
  formData,
  apiKey,
}: {
  formData: any;
  apiKey: string;
}) => {
  const { data } = await httpRequest(
    "POST",
    ApiUrls.postAdminMediaBigOwlFiles,
    undefined,
    formData,
    undefined,
    apiKey,
    "form"
  );

  return data;
};

export const requestSyncOwlRdfData = async ({
  owlMediaId,
  apiKey,
}: {
  owlMediaId: number;
  apiKey: string;
}) => {
  const params = {
    owlMediaId,
  };
  const { data } = await httpRequest(
    "POST",
    ApiUrls.rdfXmlDataList,
    undefined,
    params,
    undefined,
    apiKey
  );
};
