import { createTheme, ThemeProvider } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import GlobalStyle from "./components/Globalstyle";
import ScrollToTop from "./components/ScrollToTop";
import { routePath } from "./constants";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import DataUpload from "@pages/DataUpload";
import ImageUpload from "@pages/ImageUpload";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#2a304e",
        // light: main값을 통해 계산됨
        // dark: main값을 통해 계산됨
        // contrastText: main값을 통해 계산됨
      },
    },
    components: {
      MuiInput: {
        styleOverrides: {},
      },
    },
  });

  useEffect(() => {
    AOS.init();
  });
  return (
    <>
      <GlobalStyle />
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <ScrollToTop />
          <Routes>
            <Route path={"/"} element={<DataUpload />} />
            <Route path={routePath.DATA_UPLOAD} element={<DataUpload />} />
            <Route path={routePath.IMAGE_UPLOAD} element={<ImageUpload />} />
            {/* <Route path={routePath.INTRODUCTION} element={<Introduction />} />
            <Route path={routePath.HERITAGE} element={<Heritage />} /> */}
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
