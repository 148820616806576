import { useNavigate } from "react-router-dom";
import { Page } from "../components/Layout";
import { routePath } from "../constants";

const useControllPage = () => {
  const navicate = useNavigate();

  const handleChangePage = (page: Page) => {
    switch (page) {
      case "data-upload":
        navicate(routePath.DATA_UPLOAD);
        break;
      case "image-upload":
        navicate(routePath.IMAGE_UPLOAD);
        break;
      default:
        break;
    }
  };
  return { handleChangePage };
};
export default useControllPage;
