import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";
import { ReactComponent as Logo } from "../../assets/img/icon/logo.svg";
import { colors } from "../../theme/colors";
import { fonts } from "../../theme/fonts";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export type Page = "data-upload" | "image-upload";
export type Color = "black" | "white";

const pageString: { [key in Page]: Page } = {
  "data-upload": "data-upload",
  "image-upload": "image-upload",
};

interface Props {
  children: ReactNode;
  onChangePage?: (page: Page) => void;
  selectedMenu?: Page;
  isLoading?: boolean;
}

const Layout: React.FC<Props> = ({
  children,
  onChangePage = () => {},
  selectedMenu,
  isLoading,
}) => {
  const navigation = useNavigate();

  const handleClickHome = () => {
    navigation("/#page1");
  };

  return (
    <LayoutStyle>
      <LayoutHeaderWrap>
        <LayoutHeader>
          <LogoArea onClick={handleClickHome}>
            <Logo width={24} height={24} />
            <h1>Korean Cultural Heritage Resources</h1>
          </LogoArea>
          <nav>
            <NavWrap>
              <NavItem
                isSelected={selectedMenu === pageString["data-upload"]}
                onClick={() => onChangePage(pageString["data-upload"])}
              >
                데이터 업로드
              </NavItem>
              <NavItem
                isSelected={selectedMenu === pageString["image-upload"]}
                onClick={() => onChangePage(pageString["image-upload"])}
              >
                이미지 업로드
              </NavItem>
            </NavWrap>
          </nav>
        </LayoutHeader>
      </LayoutHeaderWrap>
      <MainWrap>{children}</MainWrap>
      {isLoading && (
        <Loading>
          <Spinner />
        </Loading>
      )}
      <div id="modal"></div>
      <ToastContainer
        position="top-center" // 알람 위치 지정
        autoClose={3000} // 자동 off 시간
        hideProgressBar // 진행시간바 숨김
        closeOnClick // 클릭으로 알람 닫기
        rtl={false} // 알림 좌우 반전
        pauseOnFocusLoss // 화면을 벗어나면 알람 정지
        draggable={false} // 드래그 가능
        pauseOnHover // 마우스를 올리면 알람 정지
        theme="dark"
        // limit={1} // 알람 개수 제한
      />
    </LayoutStyle>
  );
};
export default Layout;

const LayoutStyle = styled.div`
  min-width: 1280px;
  min-height: 100%;
  background-color: #f1f3f6;
  position: relative;
  z-index: 1;
`;
const MainWrap = styled.main`
  height: 100%;
  perspective: 100px;
`;
const LayoutHeaderWrap = styled.div`
  width: 100%;
  position: fixed;
  left: 50%;
  top: 0;
  z-index: 10;
  transform: translateX(-50%);
  background-color: #f1f3f6;
`;
const LayoutHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  max-width: 1280px;
  padding: 0 80px;
  margin: 0 auto;
  color: black;
`;

const LogoArea = styled.div`
  display: flex;
  align-items: center;
  gap: 0 10px;
  h1 {
    ${fonts("D1")};
    color: ${colors.GRAY1};
  }
  cursor: pointer;
`;
const NavWrap = styled.ul`
  display: flex;
  gap: 0 40px;
  color: white;

  li {
    cursor: pointer;
    width: fit-content;
  }
`;

const NavItem = styled.div<{
  isSelected: boolean;
}>`
  ${({ isSelected }) =>
    isSelected
      ? css`
          color: ${colors.GRAY1};
          ${fonts("H6")}
        `
      : css`
          color: ${colors.GRAY6};
          ${fonts("B4")}
        `};
  cursor: pointer;
`;

const Loading = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  z-index: 301;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
`;

const move = keyframes`
from {transform: rotate(0deg); }
to {transform: rotate(360deg);}
`;

const Spinner = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 64px;
  height: 64px;
  margin-top: -32px;
  margin-left: -32px;
  border-radius: 50%;
  border: 8px solid transparent;
  border-top-color: #2a304e;
  border-bottom-color: #2a304e;
  animation: ${move} 0.8s ease infinite;
`;
