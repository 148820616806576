import { IResponseHeritageWordCloudLists } from "@apis/heritage/interface/response";
import _ from "lodash";
import Agency from "../assets/img/heritage/agency.png";
import Character from "../assets/img/heritage/character.png";
import Event from "../assets/img/heritage/event.png";
import Heritage from "../assets/img/heritage/heritage.png";
import Individual from "../assets/img/heritage/individual.png";
import Location from "../assets/img/heritage/location.png";
import Organ from "../assets/img/heritage/organization.png";

export const nullCheck = (value: any, returnValue?: (data: any) => any) => {
  if (value !== undefined && value !== null) {
    if (returnValue) {
      return returnValue(value);
    }
    return value;
  }
  return "-";
};

export const handlePlainCultureWordCloud = (
  wordCloud: IResponseHeritageWordCloudLists[]
) => {
  const wordCloudData = wordCloud?.map((item) => ({
    text: item.withoutUriId,
    value: item.count,
    id: item.id,
  }));

  return wordCloudData;
};

/** 위도 경도 계산기 */
export const getDistance = (
  prevLat: number,
  prevLng: number,
  lat: number,
  lng: number
) => {
  const radLat1 = (Math.PI * prevLat) / 180;
  const radLat2 = (Math.PI * lat) / 180;
  const theta = prevLng - lng;
  const radTheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radLat1) * Math.sin(radLat2) +
    Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta);
  if (dist > 1) dist = 1;

  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515 * 1.609344 * 1000;

  return dist;
};

/** GeoJSON get Color */
export const getGeoJSONStrokeColor = (jimok: string) => {
  switch (jimok) {
    case "구":
      return "#e23d3a";
    case "답":
      return "#f0b041";
    case "대":
      return "#72c240";
    case "도":
      return "#6a32c9";
    case "묘":
      return "#448ef7";
    case "임":
      return "#5abfc1";
    case "잡":
      return "#eb784f";
    case "전":
      return "#20095a";
    default:
      return "1c464e";
  }
};
// if(cultureData?.label) {
//   cultureInfo.push({
//     label: "문화유산명",
//     value: cultureData?.label,
//   })
// }
// if(cultureData?.designatedDate) {
//   cultureInfo?.push({
//     label: "지정(등록)일",
//     value: cultureData?.designatedDate
//   })
// }
// if(cultureData?.)

// cultureDetail?.label && {
//   label: "문화유산명",
//   value: cultureDetail?.label,
// },
// // {
// //   label: "구분",
// //   value: "문화유산 > 유적건조물 > 종교신앙",
// // },
// // {
// //   label: "면적",
// //   value: (
// //     <div>
// //       618m<sup>2</sup>
// //     </div>
// //   ),
// // },
// // {
// //   label: "지정번호",
// //   value: "인천 유형문화재 제52호",
// // },
// {
//   label: "지정(등록)일",
//   value: cultureDetail?.designatedDate,
// },
// {
//   label: "건립시기",
//   value: cultureDetail?.created,
// },
// {
//   label: "소재지",
//   value: cultureDetail?.address,
// },
// {
//   label: "소유자(소유단체)",
//   value: cultureDetail?.manager,
// },
// {
//   label: "관리자(관리단체)",
//   value: cultureDetail?.manager,
// },
// // {
// //   label: "연혁",
// //   value: (
// //     <div>
// //       1906년 완공
// //       <br />
// //       1906년 축성
// //       <br />
// //       2003년 인천 유형문화재 제52호 지정
// //       <br />
// //       2004년 보수공사
// //       <br />
// //       2007년 주변정비공사
// //     </div>
// //   ),
// // },
// ]);

// 중복 제거
export const uniqueCheckArray = (data: any[], key: string, key2?: string) => {
  return [..._.cloneDeep(data)].filter((item, idx, arr) => {
    if (!key2)
      return arr.findIndex((i) => i[`${key}`] === item[`${key}`]) === idx;
    return (
      arr.findIndex(
        (i) =>
          i[`${key}`] === item[`${key}`] && i[`${key2}`] === item[`${key2}`]
      ) === idx
    );
  });
};

export const getImage = (type?: string) => {
  switch (type) {
    case "인물":
      return Character;
    case "단체":
      return Organ;
    case "기관":
      return Agency;
    case "개체":
      return Individual;
    case "문화":
      return Heritage;
    case "장소":
      return Location;
    case "사건":
      return Event;
    default:
      return Individual;
  }
};

export const formatFileSize = (fileSizeInBytes: number) => {
  // 1 MB = 1024 KB, 1 KB = 1024 Bytes
  const oneMBInBytes = 1024 * 1024;
  const oneKBInBytes = 1024;

  if (fileSizeInBytes >= oneMBInBytes) {
    return (fileSizeInBytes / oneMBInBytes).toFixed(2) + " MB";
  } else if (fileSizeInBytes >= oneKBInBytes) {
    return (fileSizeInBytes / oneKBInBytes).toFixed(2) + " KB";
  } else {
    return fileSizeInBytes + " Bytes";
  }
};
