import axios, { AxiosError, AxiosResponse, Method, ResponseType } from "axios";
import { HttpError } from "../models/HttpError";

axios.interceptors.request.use(
  (config) => {
    config.headers["contet-type"] = "application/json";
    config.headers["Access-Control-Allow-Origin"] = "*";
    config.headers["Accept"] = "application/json";
    if (config.method === "put") {
      config.headers["X-HTTP-Method-Override"] = "PUT";
      config.method = "post";
    }
    if (config.method === "delete") {
      config.headers["X-HTTP-Method-Override"] = "delete";
      config.method = "post";
    }

    // if (tempToken) {
    //   config.headers.Authorization = `Bearer ${tempToken}`;
    // }
    // if (!token && !tempToken && window.location.pathname !== "/login") {
    //   window.location.replace("/");
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    return Promise.reject(new HttpError(error));
  }
);

const httpRequest = <T>(
  method: Method,
  apiPath: string,
  queryParams?: Record<string, any>,
  body?: Record<string, any>,
  responseType?: ResponseType,
  apiKey?: string,
  type?: string
): Promise<AxiosResponse<T>> => {
  return axios.request({
    baseURL: process.env.REACT_APP_ROOT_URL,
    method,
    url: apiPath,
    params: queryParams,
    data: body,
    responseType,
    headers:
      type === "form"
        ? {
            "x-api-key": apiKey,
            "Content-Type": "multipart/form-data",
          }
        : {
            "x-api-key": apiKey,
          },
  });
};

export default httpRequest;
