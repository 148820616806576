export class HttpError {
  constructor(error: any) {
    this.status = 404;
    this.message = "";
    this.error = "";
    if (error) {
      const { response } = error;
      if (response) {
        const { data } = response;
        if (data) {
          const { error, message, statusCode = -1 } = data;
          this.error = error;
          this.status = statusCode;
          if (typeof message === "string") {
            this.message = message;
          } else if (Array.isArray(message)) {
            this.message = message[0] || "";
          }
        }
      }
    }
  }

  // Http status
  status: number;

  // 오류 메시지
  message: string;

  // 오류 코드
  error: string;
}
