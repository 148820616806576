import React, { useState } from "react";
import Layout from "../../components/Layout";
import { PageWrap } from "../../components/Layout/styles";
import useChangePage from "../../hooks/useChangePage";
import styled from "styled-components";
import {
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { fonts } from "theme/fonts";
import Button from "@components/Button";
import { useMutation, useQuery } from "react-query";
import {
  requestGetAdminMediaBigImages,
  requestPostAdminMediaBigImage,
} from "@apis/admin";
import Modal from "@components/Modal";
import { GetAdminMediaBigImagesResponse } from "@apis/admin/response";
import dayjs from "dayjs";
import Pagination from "@components/Pagination";
import { toast } from "react-toastify";
import { formatFileSize } from "utils";

interface Props {}

const ImageUpload: React.FC<Props> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { handleChangePage } = useChangePage();
  const [apiKey, setApiKey] = useState<string>("");
  const [keyword, setKeyword] = useState<string>("");
  const [files, setFiles] = useState<any>([]);
  const [list, setList] = useState<GetAdminMediaBigImagesResponse[]>([]);
  const [listCount, setListCount] = useState<number>(0);
  /** 페이지네이션 페이지 */
  const [currentPage, setCurrentPage] = useState(1);
  /** 검색 결과 페이지 사이즈 조정 - default 10개 */
  const [pageSize] = useState(10);

  const handleCurrentPageChange = (num: number) => {
    setCurrentPage(num);
  };

  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const { refetch } = useQuery(
    ["IMAGE_UPLOAD_LIST", currentPage],
    () =>
      requestGetAdminMediaBigImages({
        originName: keyword !== "" ? keyword : undefined,
        page: currentPage,
        pageSize: 10,
      }),
    {
      onSuccess: (data) => {
        setList(data.rows || []);
        setListCount(data.count || 0);
        setIsLoading(false);
      },
      onError: (error: any) => {
        toast.error(error.message);
        setIsLoading(false);
      },
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: uploadImage } = useMutation(requestPostAdminMediaBigImage, {
    onSuccess: () => {
      refetch();
      setModalVisible(false);
    },
    onError: (error: any) => {
      toast.error(error.message);
      setIsLoading(false);
    },
  });

  const onClickVisibleModal = () => {
    setModalVisible(!modalVisible);
  };

  const onImageUpload = () => {
    const formData = new FormData();
    formData.append("image", files);
    formData.append("folder", "image");
    setIsLoading(true);
    uploadImage({ formData, apiKey });
  };

  const onChangeImage = (event: any) => {
    const fileList = event.target.files;
    const file = fileList[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      // console.log(reader);
      // setVehicleImage(reader.result as string);
    };
    setFiles(file);
  };

  const handleSubmitSearchKeyword = () => {
    refetch();
  };

  return (
    <Layout
      isLoading={isLoading}
      selectedMenu="image-upload"
      onChangePage={handleChangePage}
    >
      <PageWrap>
        <ApiKeyWrap>
          <div style={{ whiteSpace: "nowrap" }}>
            <label>API Key</label>
          </div>
          <div style={{ width: "100%" }}>
            <CustomInput
              disableUnderline
              placeholder="API Key를 입력해주세요"
              onChange={(e) => setApiKey(e.target.value)}
            />
          </div>
        </ApiKeyWrap>
        <Header>
          <p>이미지 업로드</p>
        </Header>
        <Content>
          <ContentHeader>
            <Button onClick={onClickVisibleModal} width="100px" size="medium">
              업로드
            </Button>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmitSearchKeyword();
              }}
            >
              <HeaderRightWrap>
                <HeaderInput
                  disableUnderline
                  placeholder="검색어를 입력해주세요"
                  onChange={(e) => setKeyword(e.target.value)}
                  value={keyword}
                />
                <Button onClick={handleSubmitSearchKeyword} width="56px">
                  검색
                </Button>
              </HeaderRightWrap>
            </form>
          </ContentHeader>
          <TableContainer>
            <Table
              style={{ borderRadius: 16, overflow: "hidden", maxWidth: 1280 }}
            >
              <CustomTableHead>
                <TableRow style={{ whiteSpace: "nowrap" }}>
                  <TableCell>요청일시</TableCell>
                  <TableCell>주소 URL</TableCell>
                  <TableCell>원본 파일명</TableCell>
                  <TableCell>업로드 파일명</TableCell>
                  <TableCell>용량</TableCell>
                  <TableCell>썸네일</TableCell>
                </TableRow>
              </CustomTableHead>
              <CustomTableBody>
                {list.map((item, idx) => (
                  <TableRow
                    key={idx.toString()}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <TableCell>
                      {dayjs(item.createdAt).format("YYYY-MM-DD HH:mm")}
                    </TableCell>
                    <TableCell>{item.largeFullUrl}</TableCell>
                    <TableCell>{item.originName}</TableCell>
                    <TableCell>{item.fileName}</TableCell>
                    <TableCell>{formatFileSize(item?.size || 0)}</TableCell>
                    <TableCell>
                      <img
                        style={{ maxWidth: 120, maxHeight: 120 }}
                        src={item.largeFullUrl}
                        alt={String(item.id)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </CustomTableBody>
            </Table>
          </TableContainer>
          <PaginationWrap>
            <Pagination
              page={currentPage}
              pageSize={pageSize}
              onCurrentChange={handleCurrentPageChange}
              totalCount={listCount}
              maxLength={5}
            />
          </PaginationWrap>
        </Content>
      </PageWrap>
      <Modal visible={modalVisible} onClick={onClickVisibleModal}>
        <ModalWrap>
          <p>업로드</p>
          <UploadInput
            disableUnderline
            inputProps={{ accept: "image/*" }}
            placeholder="파일을 등록해주세요."
            onChange={onChangeImage}
            type={"file"}
          />
        </ModalWrap>
        <ModalFooter>
          <Button
            onClick={onClickVisibleModal}
            height="40px"
            color="inherit"
            style={{
              backgroundColor: "#fff",
            }}
            size="large"
          >
            취소
          </Button>
          <Button
            disabled={files.length === 0}
            onClick={onImageUpload}
            height="40px"
            size="large"
          >
            확인
          </Button>
        </ModalFooter>
      </Modal>
    </Layout>
  );
};
export default ImageUpload;

const ApiKeyWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

const CustomInput = styled(Input)`
  background-color: white;
  border-radius: 16px;
  border: 0px;
  padding: 12px 24px;
  width: 100%;
`;

const Header = styled.header`
  margin-top: 42px;
  margin-bottom: 32px;
  ${fonts("H3")}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 32px;
`;

const CustomTableHead = styled(TableHead)`
  background-color: #f6f8fb;
`;

const CustomTableBody = styled(TableBody)`
  background-color: #fff;
`;

const ModalWrap = styled.div`
  width: 80vw;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ModalFooter = styled.div`
  padding: 20px 24px;
  display: flex;
  gap: 8px;
`;

const UploadInput = styled(CustomInput)`
  & > input {
    cursor: pointer !important;
  }
  cursor: pointer !important;
  border: 1px solid #dee2e6;
`;

const HeaderInput = styled(CustomInput)`
  height: 36px;
  border-radius: 6px;
  max-width:: 420px;
`;

const HeaderRightWrap = styled.div`
  display: flex;
  gap: 12px;
  flex: 1;
  max-width: 600px;
`;

const PaginationWrap = styled.div`
  background: #fff;
  border-radius: 12px;
  padding: 20px 10px;
  margin: 20px 0px;
  align-self: center;
`;
