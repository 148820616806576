import React, { useState } from "react";
import Layout from "../../components/Layout";
import { PageWrap } from "../../components/Layout/styles";
import useChangePage from "../../hooks/useChangePage";
import styled from "styled-components";
import {
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { fonts } from "theme/fonts";
import Button from "@components/Button";
import Modal from "@components/Modal";
import Pagination from "@components/Pagination";
import dayjs from "dayjs";
import { useMutation, useQuery } from "react-query";
import {
  requestGetRdfXmlDataDetail,
  requestGetRdfXmlDataList,
  requestPostRdfXmlOwlData,
  requestSyncOwlRdfData,
} from "@apis/admin";
import { toast } from "react-toastify";

interface Props {}

const DataUpload: React.FC<Props> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { handleChangePage } = useChangePage();
  const [apiKey, setApiKey] = useState<string>("");
  const [files, setFiles] = useState<any>([]);
  const [list, setList] = useState<any[]>([]);
  const [listCount, setListCount] = useState<number>(0);
  const [detail, setDetail] = useState<any>();
  /** 페이지네이션 페이지 */
  const [currentPage, setCurrentPage] = useState(1);
  /** 검색 결과 페이지 사이즈 조정 - default 10개 */
  const [pageSize] = useState(10);

  const handleCurrentPageChange = (num: number) => {
    setCurrentPage(num);
  };

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [detailModalVisible, setDetailModalVisible] = useState<boolean>(false);

  const { refetch } = useQuery(
    ["IMAGE_UPLOAD_LIST", currentPage],
    () =>
      requestGetRdfXmlDataList({
        page: currentPage,
        pageSize: 10,
      }),
    {
      onSuccess: (data: any) => {
        setList(data.rows || []);
        setListCount(data.count || 0);
        setIsLoading(false);
      },
      onError: (error: any) => {
        toast.error(error.message);
        setIsLoading(false);
      },
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: uploadImage } = useMutation(requestPostRdfXmlOwlData, {
    onSuccess: (data: any) => {
      uploadSync({ owlMediaId: data.rows[0].id, apiKey });
    },
    onError: (error: any) => {
      toast.error(error.message);
      setIsLoading(false);
    },
  });

  const { mutate: uploadSync } = useMutation(requestSyncOwlRdfData, {
    onSuccess: () => {
      refetch();
      setModalVisible(false);
    },
    onError: (error: any) => {
      toast.error(error.message);
      setIsLoading(false);
    },
  });

  const { mutate: detailSync } = useMutation(requestGetRdfXmlDataDetail, {
    onSuccess: (data: any) => {
      setIsLoading(false);
      setDetail(data.row);
    },
    onError: (error: any) => {
      toast.error(error.message);
      setIsLoading(false);
    },
  });

  const onClickVisibleModal = () => {
    setModalVisible(!modalVisible);
  };

  const onClickDetailVisibleModal = () => {
    setDetailModalVisible(!detailModalVisible);
  };

  const onClickDetailVisibleModalRow = (item: any) => {
    detailSync({
      id: item.id as number,
      apiKey: "G7djsRjfrM6ZubedJFjOo2QO6TteAiwU",
    });
    setDetailModalVisible(true);
  };

  const onImageUpload = () => {
    const formData = new FormData();
    formData.append("file", files);
    formData.append("folder", "owl");
    setIsLoading(true);
    uploadImage({ formData, apiKey });
  };

  const onChangeImage = (event: any) => {
    const fileList = event.target.files;
    const file = fileList[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      // console.log(reader);
      // setVehicleImage(reader.result as string);
    };
    setFiles(file);
  };

  const getStatusData = (status: string) => {
    // start, error, deleteDone, neptuneRequestDone
    switch (status) {
      case "start":
        return "진행중";
      case "error":
        return "오류";
      case "deleteDone":
        return "삭제";
      case "neptuneRequestDone":
        return "완료";
      default:
        return "진행중";
    }
  };

  const getErrorNeptuneStatus = (status: string) => {
    if (
      status === "LOAD_NOT_STARTED" ||
      status === "LOAD_IN_PROGRESS" ||
      status === "LOAD_COMPLETED"
    ) {
      return "";
    }
    return status;
  };
  const getSucessNeptuneStatus = (status: string) => {
    if (
      status === "LOAD_NOT_STARTED" ||
      status === "LOAD_IN_PROGRESS" ||
      status === "LOAD_COMPLETED"
    ) {
      return status;
    }
    return "";
  };

  return (
    <Layout
      isLoading={isLoading}
      selectedMenu="data-upload"
      onChangePage={handleChangePage}
    >
      <PageWrap>
        <ApiKeyWrap>
          <div style={{ whiteSpace: "nowrap" }}>
            <label>API Key</label>
          </div>
          <div style={{ width: "100%" }}>
            <CustomInput
              disableUnderline
              placeholder="API Key를 입력해주세요"
              onChange={(e) => setApiKey(e.target.value)}
            />
          </div>
        </ApiKeyWrap>
        <Header>
          <p>RDF/XML 데이터 업로드 요청</p>
        </Header>
        <Content>
          <ContentHeader>
            <Button onClick={onClickVisibleModal} width="100px" size="medium">
              업로드
            </Button>
          </ContentHeader>
          <TableContainer>
            <Table
              style={{ borderRadius: 16, overflow: "hidden", maxWidth: 1280 }}
            >
              <CustomTableHead>
                <TableRow style={{ whiteSpace: "nowrap" }}>
                  <TableCell>요청일시</TableCell>
                  <TableCell>업로드 상태</TableCell>
                  <TableCell>요청 IP</TableCell>
                  <TableCell>요청 ID</TableCell>
                  <TableCell>Neptune 서버 요청 일시</TableCell>
                  <TableCell>파일명</TableCell>
                  <TableCell>다운로드</TableCell>
                </TableRow>
              </CustomTableHead>
              <CustomTableBody>
                {list.map((item, idx) => (
                  <CustomTableRow
                    key={idx.toString()}
                    style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                  >
                    <TableCell
                      onClick={() => onClickDetailVisibleModalRow(item)}
                    >
                      {dayjs(item.createdAt).format("YYYY-MM-DD HH:mm")}
                    </TableCell>
                    <TableCell
                      onClick={() => onClickDetailVisibleModalRow(item)}
                    >
                      {getStatusData(item.status)}
                    </TableCell>
                    <TableCell
                      onClick={() => onClickDetailVisibleModalRow(item)}
                    >
                      {item.ip}
                    </TableCell>
                    <TableCell
                      onClick={() => onClickDetailVisibleModalRow(item)}
                    >
                      {item.loadId}
                    </TableCell>
                    <TableCell
                      onClick={() => onClickDetailVisibleModalRow(item)}
                    >
                      {dayjs(item.neptuneRequestAt).format("YYYY-MM-DD HH:mm")}
                    </TableCell>
                    <TableCell
                      onClick={() => onClickDetailVisibleModalRow(item)}
                    >
                      {item.media?.originName}
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          if (item.media) {
                            const downloadLink = document.createElement("a");
                            downloadLink.href = item.media?.bufferFullUrl;
                            downloadLink.download = item.media?.originName;
                            downloadLink.target = "_blank";
                            downloadLink.click();
                          }
                        }}
                      >
                        다운로드
                      </Button>
                    </TableCell>
                  </CustomTableRow>
                ))}
              </CustomTableBody>
            </Table>
          </TableContainer>
          <PaginationWrap>
            <Pagination
              page={currentPage}
              pageSize={pageSize}
              onCurrentChange={handleCurrentPageChange}
              totalCount={listCount}
              maxLength={5}
            />
          </PaginationWrap>
        </Content>
      </PageWrap>
      <Modal visible={modalVisible} onClick={onClickVisibleModal}>
        <ModalWrap>
          <p>업로드</p>
          <UploadInput
            disableUnderline
            inputProps={{ accept: ".owl" }}
            placeholder="파일을 등록해주세요."
            onChange={onChangeImage}
            type={"file"}
          />
        </ModalWrap>
        <ModalFooter>
          <Button
            onClick={onClickVisibleModal}
            height="40px"
            color="inherit"
            style={{
              backgroundColor: "#fff",
            }}
            size="large"
          >
            취소
          </Button>
          <Button
            disabled={files.length === 0}
            onClick={onImageUpload}
            height="40px"
            size="large"
          >
            확인
          </Button>
        </ModalFooter>
      </Modal>
      <Modal visible={detailModalVisible} onClick={onClickDetailVisibleModal}>
        <ModalWrap>
          <div>
            <p style={{ marginBottom: 8 }}>요청 일시</p>
            <DetailInput
              disabled
              value={dayjs(detail?.createdAt).format("YYYY-MM-DD HH:mm")}
            />
          </div>
          <div>
            <p style={{ marginBottom: 8 }}>업로드 상태</p>
            <DetailInput disabled value={getStatusData(detail?.status)} />
          </div>
          <div>
            <p style={{ marginBottom: 8 }}>요청 IP</p>
            <DetailInput disabled value={detail?.ip} />
          </div>
          <div>
            <p style={{ marginBottom: 8 }}>요청 ID</p>
            <DetailInput disabled value={detail?.loadId} />
          </div>
          <div>
            <p style={{ marginBottom: 8 }}>Neptune 서버 요청 일시</p>
            <DetailInput
              disabled
              value={dayjs(detail?.neptuneRequestAt).format("YYYY-MM-DD HH:mm")}
            />
          </div>
          <div>
            <p style={{ marginBottom: 8 }}>파일명</p>
            <DetailInput disabled value={detail?.media?.originName} />
          </div>
          <div>
            <p style={{ marginBottom: 8 }}>Neptune 서버 성공여부</p>
            <DetailInput
              disabled
              value={getSucessNeptuneStatus(detail?.neptuneStatus)}
            />
          </div>
          <div>
            <p style={{ marginBottom: 8 }}>Neptune 서버 실패 사유</p>
            <DetailInput
              disabled
              value={getErrorNeptuneStatus(detail?.neptuneStatus)}
            />
          </div>
        </ModalWrap>
        <ModalFooter>
          {/* <Button
            onClick={onClickVisibleModal}
            height="40px"
            color="inherit"
            style={{
              backgroundColor: "#fff",
            }}
            size="large"
          >
            취소
          </Button> */}
          <Button
            onClick={onClickDetailVisibleModal}
            height="40px"
            size="large"
          >
            확인
          </Button>
        </ModalFooter>
      </Modal>
    </Layout>
  );
};
export default DataUpload;

const ApiKeyWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

const CustomInput = styled(Input)`
  background-color: white;
  border-radius: 16px;
  border: 0px;
  padding: 12px 24px;
  width: 100%;
`;

const Header = styled.header`
  margin-top: 42px;
  margin-bottom: 32px;
  ${fonts("H3")}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 32px;
`;

const CustomTableHead = styled(TableHead)`
  background-color: #f6f8fb;
`;

const CustomTableBody = styled(TableBody)`
  background-color: #fff;
`;

const ModalWrap = styled.div`
  width: 80vw;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ModalFooter = styled.div`
  padding: 20px 24px;
  display: flex;
  gap: 8px;
`;

const UploadInput = styled(CustomInput)`
  & > input {
    cursor: pointer !important;
  }
  cursor: pointer !important;
  border: 1px solid #dee2e6;
`;

const PaginationWrap = styled.div`
  background: #fff;
  border-radius: 12px;
  padding: 20px 10px;
  margin: 20px 0px;
  align-self: center;
`;

const CustomTableRow = styled(TableRow)`
  &:hover {
    background-color: #eee;
  }
`;

const DetailInput = styled(CustomInput)`
  height: 40px;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  &:before {
    border-bottom-style: none !important;
  }
  padding: 12px;
`;
